import { colors } from "@material-ui/core";

const white = "#FFFFFF";
const black = "#000000";
const blackContrast = "rgba(0, 0, 0, 0.87)";

export const paletteLight = {
	common: {
		black,
		white,
	},
	type: "light",
	primary: {
		light: "#67bcb6",
		main: "#67bcb6",
		dark: "#67bcb6",
		contrastText: white,
	},
	background: {
		default: '#fff'
	},
	secondary: {
		light: colors.indigo["A200"],
		main: colors.indigo["A400"],
		dark: colors.indigo["A700"],
		transparent: (opacity) => `rgb(48, 79, 254, ${opacity})`,
		contrastText: white,
	},
	error: {
		light: colors.red[300],
		main: colors.red[500],
		dark: colors.red[700],
		transparent: (opacity) => `rgb(229, 115, 115, ${opacity})`,
		contrastText: white,
	},
	warning: {
		light: colors.orange[300],
		main: colors.orange[500],
		dark: colors.orange[700],
		contrastText: blackContrast,
	},
	info: {
		light: "#5ba39e",
		main: "#5ba39e",
		dark: "#5ba39e",
		contrastText: white,
	},
	success: {
		light: colors.green[300],
		main: colors.green[500],
		dark: colors.green[700],
		contrastText: blackContrast,
	},
	text: {
		primary: "rgba(0,0,0,0.87)",
		secondary: "rgba(0,0,0,0.54)",
		disabled: "rgba(0,0,0,0.38)",
		hint: "rgba(0,0,0,0.38)",
	},
	divider: "rgba(0,0,0,0.12)",
	background: {
		paper: white,
		default: colors.grey[100],
	},
	action: {
		active: "rgba(0,0,0,0.54)",
		hover: "rgba(0,0,0,0.1)",
		hoverOpacity: 0.04,
		selected: "rgba(0,0,0,0.2)",
		selectedOpacity: 0.08,
		disabled: "rgba(0,0,0,0.26)",
		disabledBackground: "rgba(0,0,0,0.12)",
		disabledOpacity: 0.38,
		focus: "rgba(0,0,0,0.12)",
		focusOpacity: 0.12,
		activatedOpacity: 0.12,
	},
};
