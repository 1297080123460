import { ApolloClient, HttpLink, InMemoryCache, ApolloLink } from "apollo-boost";
import { isLoggedIn, getAccessToken } from "../requests/utils/auth";

const endpoint = "https://188.26.105.15:4000";

const authLink = new ApolloLink((operation, forward) => {
	if (isLoggedIn()) {
		operation.setContext({
			headers: {
				authorization: "Bearer " + getAccessToken(),
			},
		});
	}
	return forward(operation);
});

const client = new ApolloClient({
	link: ApolloLink.from([authLink, new HttpLink({ uri: endpoint })]),
	cache: new InMemoryCache(),
});

export { client as default };
