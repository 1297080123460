import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid, CssBaseline, Grow } from "@material-ui/core";
import * as lang from "../../utils/strings";
import { WelcomeMessage, QuickAccess, TotalUsers, LatestOrders, DocTabs } from "./components";
import { DOCUMENT_SEARCH_QUERY } from "../../requests/documentRequests";
import { useLazyQuery } from "@apollo/react-hooks";
import { CustomModal } from "../../components";
import { useForm } from "react-hook-form";
import { downloadFile } from "../../utils/fetchFunctions";

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(4),
	},
	display: {
		minWidth: "250px",
	},
	trans: {
		transition: theme.transitions.create("all", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
}));

const Dashboard = (props) => {
	const classes = useStyles();
	const { language } = props;
	const [selectedItem, setSelectedItem] = useState(null);
	const [docType, setDocType] = useState(null);

	const [
		getDocs,
		{ data: dataDocs, loading: loadingDocs, networkStatus: networkStatusDocs, refetch: refetchDocs },
	] = useLazyQuery(DOCUMENT_SEARCH_QUERY, {
		fetchPolicy: "no-cache",
	});

	const handleRowClick = (data) => {
		// if (data.state === "CLOSED") downloadFile(data.name, data.dataUri);
		// else {
		setSelectedItem(data.id);
		setDocType(data.type);
		// }
	};

	// const { register, handleSubmit, errors, clearError, setValue, getValues, reset, unregister } = useForm({});

	useEffect(() => {
		document.title = `RaisisMed`;
	}, []);

	return (
		<div className={classes.root}>
			<CssBaseline />
			<CustomModal
				form
				customBackground
				fullScreen
				language={language}
				title={docType && lang[docType][language]}
				open={docType !== null}
				onClose={() => setDocType(null)}
				content={
					docType !== null && (
						<DocTabs
							language={language}
							id={selectedItem}
							onClose={() => setDocType(null)}
							form={{
								docType,
								language,
								refetchDocs,
							}}
						/>
					)
				}
			/>
			<Grid container spacing={4}>
				<Grid item xs={12} className={classes.trans}>
					<WelcomeMessage {...props} className={classes.display} />
				</Grid>
				<Grid item lg={8} sm={6} xl={9} xs={12}>
					<Grow in={true}>
						<QuickAccess language={language} className={classes.display} refetchDocs={refetchDocs} />
					</Grow>
				</Grid>
				<Grid item lg={4} sm={6} xl={3} xs={12}>
					<Grow in={true}>
						<TotalUsers className={classes.display} />
					</Grow>
				</Grid>
				<Grid item lg={12} md={12} xl={9} xs={12}>
					<Grow in={true}>
						<LatestOrders
							{...props}
							custom
							fetchData={(rows, page, filters) =>
								getDocs({
									variables: {
										where: {
											AND: filters,
										},
										pagination: {
											first: rows,
											skip: Math.max(page, 0) * rows,
										},
									},
								})
							}
							refetch={refetchDocs}
							loading={loadingDocs && !networkStatusDocs === 4}
							called={true}
							// onOpen={() => setAddModal(true)}
							data={dataDocs?.documents?.documents !== undefined ? dataDocs.documents.documents : []}
							count={dataDocs?.documents?.count !== undefined ? dataDocs.documents.count : 0}
							onRowClick={handleRowClick}
							className={classes.display}
						/>
					</Grow>
				</Grid>
			</Grid>
		</div>
	);
};

export default Dashboard;
