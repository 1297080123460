import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/styles";
import { useMediaQuery } from "@material-ui/core";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";
import Footer from "./Footer";

const useStyles = makeStyles((theme) => ({
	root: {
		height: "100%",
		position: 'relative',
		backgroundColor: '#fff'
	},
	shiftContent: {
		paddingLeft: 240,
		height: '100%',
		flex: 1,
		display: 'flex',
		flexDirection: 'column'
	},
	content: {
		height: "100%",
		backgroundColor: 'transparent',
		borderTopLeftRadius: '30px',
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		boxShadow: 'inset 0 5px 30px 0 rgba(0, 0, 0, .1)',
		'& > *': {
			flex: 1,
			height: '100%'
		}
	},
}));

const Main = (props) => {
	const { children, me } = props;

	const classes = useStyles();
	const theme = useTheme();
	const isDesktop = useMediaQuery(theme.breakpoints.up("lg"), {
		defaultMatches: true,
	});

	const [openSidebar, setOpenSidebar] = useState(false);

	const handleSidebarOpen = () => {
		setOpenSidebar(true);
	};

	const handleSidebarClose = () => {
		setOpenSidebar(false);
	};

	const shouldOpenSidebar = isDesktop ? true : openSidebar;

	return (
		<>
			<Topbar onSidebarOpen={handleSidebarOpen} {...props} />
			<div
				className={clsx({
					[classes.root]: true,
					[classes.shiftContent]: isDesktop,
				})}
			>
				<Sidebar
					{...props}
					onClose={handleSidebarClose}
					open={shouldOpenSidebar}
					variant={isDesktop ? "persistent" : "temporary"}
				/>
				<main className={classes.content}>
					{children}
					{/* <Footer /> */}
				</main>
			</div >
		</>
	);
};

Main.propTypes = {
	children: PropTypes.node,
};

export default Main;
