import React, { useEffect, useState } from "react";
import { Router } from "react-router-dom";
import { ThemeProvider } from "@material-ui/styles";
import browserHistory from "./config/history";
import "./App.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import Routes from "./config/Routes";
import getTheme from "./theme";
import { isLoggedIn, logout } from "./requests/utils/auth";
import getInitialTheme from "./config/getTheme";
import getInitialLanguage from "./config/getLanguage";
import apolloClient from "./config/apolloClient";
import { ApolloProvider } from "@apollo/react-hooks";
import { SnackbarProvider, useSnackbar } from "notistack";
import { LANGUAGE_CHANGED_SUCCESS } from "./utils/strings";
import { IconButton } from "@material-ui/core";
import { Clear } from "@material-ui/icons";

function App(props) {
	const [language, setLanguage] = useState(getInitialLanguage());
	const [isAuthenticated, setAuthentication] = useState(isLoggedIn());
	const { enqueueSnackbar } = useSnackbar();

	const handleLogin = () => {
		setAuthentication(true);
		browserHistory.push("/");
	};
	const handleLogout = () => {
		logout();
		setAuthentication(false);
		browserHistory.push("/sign-in");
	};

	useEffect(() => {
		localStorage.setItem("language", language);
	}, [language]);

	useEffect(() => {
		document.title = "RaisisMed";
		if (!isAuthenticated && browserHistory.location.pathname !== "/sign-in")
			handleLogout();
	});

	useEffect(() => {
		document.body.style.backgroundColor = '#fafafa';
		document.body.style.display = 'flex';
		document.body.style.minHeight = '100vh';
		document.body.style.flexDirection = 'column';
		document.body.style.width = '100%';
	}, []);

	return (
		<ApolloProvider client={apolloClient}>
			<Router history={browserHistory}>
				<Routes
					{...props}
					language={language}
					togglelanguage={() => {
						setLanguage(language === "ro" ? "en" : "ro");
						enqueueSnackbar(
							LANGUAGE_CHANGED_SUCCESS[
							language === "ro" ? "en" : "ro"
							],
							{
								variant: "success",
							}
						);
					}}
					auth={{
						isAuthenticated,
						login: handleLogin,
						logout: handleLogout,
					}}
				/>
			</Router>
		</ApolloProvider>
	);
}

export default function AppWithNotifications() {
	const [theme, setTheme] = useState(getInitialTheme());
	const notistackRef = React.createRef();
	const onClickDismiss = (key) => () => {
		notistackRef.current.closeSnackbar(key);
	};

	useEffect(() => {
		localStorage.setItem("theme", theme.palette.type);
	}, [theme]);

	return (
		<ThemeProvider theme={theme}>
			<SnackbarProvider
				maxSnack={3}
				preventDuplicate
				hideIconVariant
				ref={notistackRef}
				action={(key) => (
					<IconButton onClick={onClickDismiss(key)}>
						<Clear />
					</IconButton>
				)}
			>
				<App
					theme={theme}
					toggletheme={() =>
						setTheme(
							theme.palette.type === "light"
								? getTheme("dark")
								: getTheme("light")
						)
					}
				/>
			</SnackbarProvider>
		</ThemeProvider>
	);
}
