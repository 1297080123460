import { createMuiTheme } from "@material-ui/core";

import { paletteDark } from "./palette-dark";
import { paletteLight } from "./palette-light";
import typography from './typography';
import overrides from './overrides';

const getTheme = (mode) => {
	const palette = mode === "light" ? paletteLight : paletteDark;
	return createMuiTheme({
		palette,
		typography,
		overrides,
		shape: {
			borderRadius: 15,
		},
		zIndex: {
			appBar: 1200,
			drawer: 1100,
		},
		MuiFilledInput: {
			root: {
				backgroundColor: '#EFEFEF',
				borderRadius: '41px',
				borderTopLeftRadius: 'none',
				borderTopRightRadius: 'none',
				'&:hover': {
					backgroundColor: 'rgba(0, 0, 0, 0.08)',
				},
			},
			input: {
				padding: '18px 40px',
				fontSize: '1.25em',
			},
			underline: {
				'&&&:before': {
					borderBottom: 'none',
				},
				'&&:after': {
					borderBottom: 'none',
				},
			},
		},
		MuiInputBase: {
			root: {
				borderRadius: '41px',
			},
			input: {
				fontSize: '1em',
				fontWeight: '500',
				borderRadius: 'inherit',
				color: '#242424',
			},
		},
		MuiOutlinedInput: {
			root: {
				borderRadius: '41px'
			}
		}
	});
};

export default getTheme;
