import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { AppBar, Toolbar, Badge, Hidden, IconButton, Typography } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import {
	Notifications,
	Brightness4 as DarkIcon,
	Brightness5 as LightIcon,
	// Translate,
} from "@material-ui/icons";
import InputIcon from "@material-ui/icons/Input";
import { useSnackbar } from "notistack";
import * as lang from "../utils/strings";
import { CustomModal } from "../components";

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'relative',
		width: '100%',
		height: '80px',
		boxShadow: "none",
		backgroundColor: '#fefefe',
		'& .MuiToolbar-root': {
			position: 'relative',
			width: '100%',
			height: '100%',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
		}
	},
	logoContainer: {
		'& img': {
			width: '175px'
		}
	},
	flexGrow: {
		flexGrow: 1,
	},
	signOutButton: {
		marginLeft: theme.spacing(1),
	},
}));

const Topbar = (props) => {
	const { className, onSidebarOpen, toggletheme, auth, language } = props;

	const classes = useStyles();

	const [notifications] = useState([]);
	const [logoutModal, setLogoutModal] = useState(false);
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	return (
		<AppBar className={clsx(classes.root, className)}>
			<CustomModal
				open={logoutModal}
				title={lang.CONFIRM_LOGOUT[language]}
				language={language}
				onClose={() => setLogoutModal(false)}
				onExecute={() => {
					auth.logout();
					enqueueSnackbar(lang.LOGOUT_SUCCESS[language], {
						variant: "info",
					})
				}}
			/>
			<Toolbar>
				<RouterLink to="/">
					<Typography variant="h1">
						<div className={classes.logoContainer}>
							<img src="/images/Logo_RaisisMed.svg" />
						</div>
					</Typography>
				</RouterLink>
				<div className={classes.flexGrow} />
				<Hidden mdDown>
					<IconButton color="primary">
						<Badge badgeContent={notifications.length} color="primary" variant="dot">
							<Notifications />
						</Badge>
					</IconButton>
					<IconButton className={classes.signOutButton} color="primary" onClick={() => setLogoutModal(true)}>
						<InputIcon />
					</IconButton>
				</Hidden>
				<Hidden lgUp>
					<IconButton color="primary" onClick={onSidebarOpen}>
						<MenuIcon />
					</IconButton>
				</Hidden>
			</Toolbar>
		</AppBar>
	);
};

Topbar.propTypes = {
	className: PropTypes.string,
	onSidebarOpen: PropTypes.func,
};

export default Topbar;
