import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { CssBaseline, Grow, Grid } from "@material-ui/core";
import { RegistriesTable } from "./components";
import * as lang from "../../utils/strings";
import { useLazyQuery } from "@apollo/react-hooks";
import { REGISTRIES_SEARCH_QUERY } from "../../requests";
import { exportData } from "../../utils/fetchFunctions";
import { useSnackbar } from "notistack";
import { CustomModal } from "../../components";
import moment from "moment";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { ro, enUS } from "date-fns/esm/locale";

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(4),
	},
	display: {
		minWidth: "250px",
	},
}));

const Registries = (props) => {
	const classes = useStyles();
	const { language } = props;
	const [addModal, setAddModal] = useState(false);
	const { enqueueSnackbar } = useSnackbar();
	const [filter, setFilter] = useState("MED_INT");

	const [dateInterval, setDateInterval] = useState(false)

	const data = [];
	const handleRowClick = (data) => console.log(data);

	const handleExportRegistries = () => {
		let fileName = `file-${new Date().getTime()}.csv`;
		exportData("REGS", null, null, null, dateInterval.start, dateInterval.end)
			.then((response) => {
				console.log(response);
				if (response.status >= 400) {
					enqueueSnackbar(lang.SOMETHING_WRONG[language], {
						variant: "error",
					});
				}
				return response.json();
			})
			.then((response) => {
				setDateInterval(false);
				try {
					var link = document.createElement("a");
					link.href = response.file;
					link.download = fileName;
					link.click();
					// window.open(fileURL);
				} catch (e) {
					console.log(e);
					enqueueSnackbar(lang.SOMETHING_WRONG[language], {
						variant: "error",
					});
				}
			})
			.catch((err) => {
				console.log(err);
				enqueueSnackbar(lang.SOMETHING_WRONG[language], {
					variant: "error",
				});
			});
	};
	const [
		getRegistries,
		{
			data: dataRegs,
			loading: loadingRegs,
			networkStatus: networkStatusRegs,
			called: calledRegs,
			refetch: refetchRegs,
		},
	] = useLazyQuery(REGISTRIES_SEARCH_QUERY, {
		fetchPolicy: "no-cache",
	});

	useEffect(() => {
		document.title = `RaisisMed - ${lang.REGISTRIES[language]}`;
	}, []);

	return (
		<div className={classes.root}>
			<CssBaseline />
			{addModal}
			{/* <Button
				onClick={() =>
					getRegistries({
						variables: {
							query: null,
							pagination: {
								first: 5,
								skip: 0
							}
						},
					})
				}
			>
				GO
			</Button> */}
			<Grow in={true}>
				<RegistriesTable
					custom
					language={language}
					data={dataRegs?.registries?.registries !== undefined ? dataRegs.registries.registries : []}
					onOpen={() => setAddModal(true)}
					loading={loadingRegs && !loadingRegs === 4}
					called={calledRegs}
					count={dataRegs?.registries?.count !== undefined ? dataRegs.registries.count : 0}
					onRowClick={handleRowClick}
					onExport={() => setDateInterval({
						start: moment().format('YYYY-MM-DD'),
						end: moment().add(1, 'day').format('YYYY-MM-DD'),
					})}
					fetchData={(rows, page) =>
						getRegistries({
							variables: {
								where: {
									type: filter,
								},
								pagination: {
									first: rows,
									skip: Math.max(page, 0) * rows,
								},
							},
						})
					}
					filter={filter}
					setFilter={setFilter}
					className={classes.display}
				/>
			</Grow>
			<CustomModal
				language={language}
				open={Boolean(dateInterval)}
				title={lang.FILTERS[language]}
				onClose={() => setDateInterval(false)}
				onExecute={handleExportRegistries}
				content={
					<Grid container spacing={2}>
						<Grid item xs={12} md={6}>
							<MuiPickersUtilsProvider utils={DateFnsUtils} locale={language === "ro" ? ro : enUS}>
								<DatePicker
									autoOk
									variant="inline"
									inputVariant="outlined"
									fullWidth
									name="start"
									label={lang.FROM_DATE[language]}
									format="dd/MM/yyyy"
									onChange={(value) => {
										setDateInterval({
											start: moment(value).format('YYYY-MM-DD'),
											end: dateInterval.end,
										})
									}}
									value={dateInterval.start}
								/>
							</MuiPickersUtilsProvider>
						</Grid>
						<Grid item xs={12} md={6}>
							<MuiPickersUtilsProvider utils={DateFnsUtils} locale={language === "ro" ? ro : enUS}>
								<DatePicker
									autoOk
									variant="inline"
									inputVariant="outlined"
									fullWidth
									name="end"
									label={lang.TO_DATE[language]}
									format="dd/MM/yyyy"
									onChange={(value) => {
										setDateInterval({
											start: dateInterval.start,
											end: moment(value).format('YYYY-MM-DD'),
										})
									}}
									value={dateInterval.end}
								/>
							</MuiPickersUtilsProvider>
						</Grid>
					</Grid>
				}
			/>
		</div>
	);
};

export { Registries as default };
